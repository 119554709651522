import React, { useEffect, useState } from "react";
import SwiperCore, {
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
  Keyboard,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "tw-elements";
import { getClientData } from "../core/_requests";
import axios from "axios";

SwiperCore.use([
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
  Keyboard,
  EffectFade,
]);

type Props = {
  clientData: any;
  generalSettings: any;
  setClientData: any;
};

const Slider: React.FC<Props> = ({
  clientData,
  setClientData,
  generalSettings,
}) => {
  const [slider, setSlider]: any = useState(false);
  const [slider_Links, setSlider_Links]: any = useState<any>([]);
  const [orientations, setOrientations] = useState<{ [key: string]: string }>(
    {}
  );

  const pathArray = window.location.pathname.split("/");
  const id = pathArray[2];
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    let linkLengths = clientData.Private_URL_5_Picture_url;

    let linkLength = linkLengths
      .replace("[", "")
      .replace("]", "")
      .replaceAll("'", "")
      .split(",");

    linkLength = linkLength.length > 0 ? linkLength.reverse() : linkLength;

    setSlider_Links(linkLength);
  }, [clientData]);

  useEffect(() => {
    const checkOrientations = async () => {
      const orientations: { [key: string]: string } = {};

      await Promise.all(
        slider_Links.map((link: string) => {
          return new Promise<void>((resolve) => {
            if (link && !link.includes(".mp4")) {
              const img = new Image();
              img.src = link;
              img.onload = () => {
                orientations[link] =
                  img.width > img.height ? "horizontal" : "vertical";
                resolve();
              };
            } else {
              resolve();
            }
          });
        })
      );

      setOrientations(orientations);
    };

    if (slider_Links.length > 0) {
      checkOrientations();
    }
  }, [slider_Links]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getClientData(id);
        setClientData((prevData: any) => {
          // Only update state if the data is different
          if (JSON.stringify(prevData) !== JSON.stringify(response.data)) {
            return response.data;
          }
          return prevData;
        });
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData(); // Initial fetch before setting up the interval

    let intervalId = setInterval(fetchData, 3000);

    return () => {
      clearInterval(intervalId); // Clear the interval when the component is unmounted
    };
  }, [id]);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="relative theme-bg-image w-full h-full p-4 mx-auto rounded-md ">
        <div className="relative z-10 h-full">
          <Swiper
            key={slider_Links.join()}
            effect="fade"
            speed={1500}
            fadeEffect={{ crossFade: true }} // Add crossFade for smoother transitions
            keyboard={{ enabled: true }}
            // mousewheel
            spaceBetween={10}
            centeredSlides
            grabCursor
            slidesPerView={1}
            loop
            // autoHeight
            autoplay={{ delay: 2000 }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            }}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            className="m-4"
          >
            {slider_Links.map((link: any, index: number) => {
              const orientation = orientations[link] || "";
              return (
                <SwiperSlide key={index}>
                  {link && link.includes(".mp4") ? (
                    <video
                      className="!max-h-[90vh] w-[100vw] object-contain"
                      controls
                      autoPlay
                      muted
                      loop
                    >
                      <source src={link} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      className={` max-h-[90vh] w-[80vw] object-contain ${orientations[link]}`}
                      src={link}
                      alt="share"
                    />
                  )}
                  {/* <img
                      className={` max-h-[90vh] w-[80vw] object-contain ${orientations[link]}`}
                      src={link}
                      alt="share"
                    /> */}
                </SwiperSlide>
              );
            })}
          </Swiper>
          {window.innerWidth > 850 ? (
            <div className="flex flex-col items-center absolute bottom-10 ">
              <p className="font-bold text-3xl slider-text">Scan</p>
              <p className="font-bold slider-text">or</p>
              <p className="font-bold text-3xl slider-text">Click</p>
              <p className="font-bold slider-text">to send photos</p>
              <a href={window.location.href.replace("/slider", "")}>
                <img
                  src={clientData?.QR_Code_image_URL}
                  height={120}
                  width={120}
                  alt=""
                  className="z-[100] relative cursor-pointer"
                ></img>
              </a>
            </div>
          ) : (
            <div className="flex flex-col items-center absolute left-[33%] bottom-2">
              <p className="font-bold text-3xl slider-text">Scan</p>
              <p className="font-bold slider-text">or</p>
              <p className="font-bold text-3xl slider-text">Click</p>
              <p className="font-bold slider-text">to send photos</p>
              <a href={window.location.href.replace("/slider", "")}>
                <img
                  src={clientData?.QR_Code_image_URL}
                  height={120}
                  width={120}
                  alt=""
                  className="z-[100] relative cursor-pointer"
                ></img>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Slider;
