import { FC, useContext, useEffect, useState } from "react";
import { decode } from "html-entities";
import { RWebShare } from "react-web-share";
import SocialMedia from "./SocialMedia";
import vCardJS from "vcards-js";
import axios from "axios";

type Props = {
  clientData: any;
  generalSettings: any;
};

const Header: FC<Props> = ({ clientData, generalSettings }) => {
  const menuButton = document.getElementById("hamburger_menu");
  const [adData, setAdData]: any = useState<any>();
  const [lockIcon, setLockIcon] = useState<any>();

  const iconItems = clientData.icons_order
    ? clientData.icons_order.split(",")
    : [];

  const getBase64FromUrl = async (url: any) => {
    const { data } = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  return (
    <div className="site-header sticky top-0">
      <div className="flex flex-col">
        <div className="w-full flex flex-row items-center justify-between md:justify-center mx-auto mt-2 relative ">
          {clientData.Payment_icon_active === "1" &&
            clientData.payment_icon_position === "Top" && (
              <img
                src={
                  clientData.Payment_icon_URL
                    ? clientData.Payment_icon_URL
                    : "https://zaags.com/wp-content/uploads/2024/10/shop.png"
                }
                height={60}
                width={60}
                alt=""
                className=" social-icon-color max-w-[20%] cursor-pointer mx-4"
                onClick={() => {
                  document.getElementById("payment")?.click();
                }}
              ></img>
            )}
          {clientData.logo_url && (
            <div className="flex flex-row justify-center">
              <img
                alt="Logo"
                src={clientData.logo_url}
                className={`max-h-[17vh] max-w-[50vw] ${
                  clientData.is_bnb === "1" && "cursor-pointer"
                } `}
                onClick={() =>
                  clientData.is_bnb === "1" &&
                  window.open(`https://airstay.app`, "_blank")
                }
              />
            </div>
          )}
          {generalSettings?.icon_hamburger && (
            <img
              src={generalSettings?.icon_hamburger}
              height={50}
              width={50}
              alt=""
              className={`social-icon-color cursor-pointer mx-4 ${
                clientData.logo_url ? "" : "mt-12"
              }`}
              onClick={() => document.getElementById("hamburger_menu")?.click()}
            ></img>
          )}
        </div>
        <div
          className={`text-center text-600 theme-button-background theme-text-color trans ${
            clientData.logo_url ? "" : "mt-14"
          } `}
          translate="no"
          dangerouslySetInnerHTML={{
            __html: decode(clientData.Title_tag_WebSite),
          }}
        ></div>
      </div>
    </div>
  );
};
export default Header;
